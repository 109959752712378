
import { defineComponent, PropType } from 'vue';
import { surveysSubProps } from '@/utils/request';
import MortalityGraphOne from './MortalityGraphOne.vue';
import MortalityGraphTwo from './MortalityGraphTwo.vue';

export default defineComponent({
  name: 'MortalityGraphs',
  components: {
    MortalityGraphOne,
    MortalityGraphTwo,
  },
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
    data: {
      type: Array as PropType<surveysSubProps<'mort'>[]>,
      defualt: [] as surveysSubProps<'mort'>[],
    },
  },
});
