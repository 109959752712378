
import { defineComponent, PropType } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { surveysSubProps } from '@/utils/request';

export default defineComponent({
  name: 'MortalityGraphTwo',
  mounted() {
    this.profile();
  },
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
    data: {
      type: Array as PropType<surveysSubProps<'mort'>[]>,
      defualt: [] as surveysSubProps<'mort'>[],
      require: true,
    },
  },
  computed: {
    applyFilters(): number[] {
      let mortalityData: surveysSubProps<'mort'>[] = [];
      if (this.data) mortalityData = this.data;
      let arr:(number|undefined)[] = [];
      if (this.viewBy === 'CRUD') {
        arr = mortalityData.map((x:surveysSubProps<'mort'>) => (x?.keystats?.crudeMortalityRate?.all?.stat));
      } else if (this.viewBy === 'UnderFive') {
        arr = mortalityData.map((x:surveysSubProps<'mort'>) => (x?.keystats?.under5MortalityRate.stat));
      }
      arr = arr.filter((x) => (typeof x !== 'undefined'));
      return arr as number[];
    },
  },
  methods: {
    profile() {
      const { width, height } = (this.$refs.mortGraphTwo as Element).getBoundingClientRect();
      const total = (this.applyFilters?.length || 0);
      const layout = {
        height: 300,
        margin: {
          t: 25,
          l: 25,
        },
        legend: {
          y: 0.5,
          font: {
            family: 'DINNextLTPro-Medium',
            size: 14,
            color: '#363534',
          },
        },
        annotations: [
          {
            text: `${total}`,
            x: 0.5,
            y: 0.55,
            showarrow: false,
            font: {
              family: 'DINNextLTPro-Bold',
              size: 37,
              color: '#363534',
            },
          },
          {
            text: 'Total Surveys',
            x: 0.5,
            y: 0.42,
            showarrow: false,
            font: {
              family: 'DINNextLTPro-Bold',
              size: 14,
              color: '#363534',
            },
          },
        ],
      };
      const data = this.renderMortalityGraphData();
      // @ts-expect-errror enum error
      Plotly.newPlot('mort-graph-two', data, layout);
    },
    renderMortalityGraphData(): Data[] {
      const data = this.applyFilters;
      const values = [0, 0];
      if (this.thresholds) {
        data.forEach((v) => {
          // @ts-expect-error this thresholds
          values[this.thresholds(v)] += 1;
        });
      }
      let labels = ['Non-emergency < 1', 'Emergency ≥ 1'];
      if (this.viewBy === 'UnderFive') labels = ['Non-emergency < 2', 'Emergency ≥ 2'];
      const traces:Data = {
        values,
        labels,
        domain: { column: 0 },
        sort: false,
        direction: 'clockwise',
        textinfo: 'none',
        marker: {
          colors: ['#2caf80', '#e41919'],
        },
        rotation: -60,
        hovertemplate: '<b style="color: %{color}">&#10687;</b>  <span><b>%{text}</b>  %{percent}, n=%{value}</span><extra></extra>',
        text: ['Non-emergency', 'Emergency'],
        hoverinfo: 'label+percent+name',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        hole: 0.6,
        type: 'pie',
      };
      return [traces];
    },
  },
  watch: {
    applyFilters() {
      this.profile();
    },
  },
});
