
import { defineComponent, PropType } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';

export default defineComponent({
  name: 'lineGraph',
  props: {
    lineId: {
      type: String,
    },
    header: {
      type: String,
    },
    critical: {
      type: Number,
      default: 1,
    },
    data: {
      type: Array as PropType<Array<number[]>>,
    },
  },
  mounted() {
    const { width } = (this.$refs.crudLineChart as Element).getBoundingClientRect();
    const layout = {
      width,
      height: 145,
      /*
      annotations: [{
        // range diff * critical value
        x: 0.365,
        xref: 'x domain',
        text: `${this.critical}`,
        arrowhead: 2,
        ax: 0,
        ay: -20,
        yshift: 10,
        font: {
          family: 'DINNextLTPro-Bold',
          size: 15,
        },
      }],
      */
      margin: {
        t: 80,
        l: 25,
        r: 20,
        b: 50,
      },
      title: {
        text: this.$props.header,
        font: {
          family: 'DINNextLTPro-Medium',
          color: '#8e908f',
          size: 15,
        },
        xref: 'paper',
        x: 0,
        y: 0.8,
      },
      paper_bgcolor: 'rgb(216, 216, 216, 0.15)',
    };
    const t = this.renderLine();
    // @ts-expect-error enum error expected
    Plotly.newPlot(this.$props.lineId, t, layout);
  },
  methods: {
    renderLine():Data[] {
      const traces:Data = {
        type: 'indicator',
        mode: 'gauge',
        gauge: {
          shape: 'bullet',
          axis: {
            range: [null, (this.critical * 2)],
            tickfont: {
              family: 'DINNextLTPro-Medium',
              color: '#8e908f',
              size: 12,
            },
            tickformat: '.1f',
          },
          borderwidth: 0,
          steps: [
            {
              range: [0, this.critical],
              color: '#2db080',
            },
            {
              range: [this.critical, (this.critical * 2)],
              color: '#e3445b',
            },
          ],
        },
        domain: { x: [0, 2] },
      };

      return [traces];
    },
  },
});
