
import { defineComponent, PropType } from 'vue';
import { surveysSubProps } from '@/utils/request';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { Empty } from 'ant-design-vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'CauseOfDeath',
  components: {
    InfoCircleOutlined,
    Empty,
  },
  props: {
    data: {
      type: Array as PropType<Array<surveysSubProps<'mort'>>>,
    },
  },
  data() {
    return {
      dataExists: true,
    };
  },
  mounted() {
    this.cause();
  },
  computed: {
    getLoading() {
      return this.$store.getters['surveysMortality/getLoading'];
    },
    applyFilters() {
      const mortality: surveysSubProps<'mort'>[]|undefined = this.data;
      const causeOfDeath = [0, 0, 0];
      let causeOfDeathCounts:(number|string)[] = [0, 0, 0];
      let totalDeathCounts = 0;
      let traum:(number|string) = 0;
      let nonTraum:(number|string) = 0;
      let unk:(number|string) = 0;
      if (mortality && mortality.length > 0) {
        mortality.forEach((s) => {
          const death = s?.keystats?.causeOfDeath;
          const deathCount = s?.keystats?.deathCount;
          if (death && (deathCount || deathCount === 0)) {
            const traumatic = death.find((i) => (i?.label === 'Traumatic'));
            const nonTraumatic = death.find((i) => (i?.label === 'Non-traumatic'));
            const unknown = death.find((i) => (i?.label === 'Unknown'));
            totalDeathCounts += s?.keystats?.deathCount;
            if (traumatic) (traum as number) += (traumatic.percent * deathCount);
            if (nonTraumatic) (nonTraum as number) += (nonTraumatic.percent * deathCount);
            if (unknown) (unk as number) += (unknown.percent * deathCount);
          }
        });
        causeOfDeath[0] = (traum / totalDeathCounts) * 100;
        causeOfDeath[1] = (nonTraum / totalDeathCounts) * 100;
        causeOfDeath[2] = (unk / totalDeathCounts) * 100;
        if (traum) traum = traum.toFixed(0);
        if (nonTraum) nonTraum = nonTraum.toFixed(0);
        if (unk) unk = unk.toFixed(0);
        causeOfDeathCounts = [traum, nonTraum, unk];
      }
      return { causeOfDeath, causeOfDeathCounts };
    },
  },
  methods: {
    cause() {
      const { width, height } = (this.$refs.causeOfDeathGraph as Element).getBoundingClientRect();
      const layout = {
        width,
        height,
        legend: {
          xanchor: 'center',
          yanchor: 'top',
          y: -0.1,
          x: 0.5,
          orientation: 'h',
          font: {
            family: 'DINNextLTPro-Medium',
            size: 12,
          },
        },
        margin: {
          t: 20,
          l: 25,
          r: 20,
          b: 0,
        },
      };
      const t = this.renderCOD();
      // @ts-expect-error enum error expected
      Plotly.newPlot('COD-graph', t, layout);
    },
    renderCOD():Data[] {
      const { causeOfDeath, causeOfDeathCounts } = this.applyFilters;
      if (this.getLoading === 'done') {
        this.dataExists = !(causeOfDeath.every((c) => (c === 0 || typeof (c) !== 'number')));
      }
      const COD = causeOfDeath.map((x) => (typeof (x) === 'number' ? x.toFixed(2) : '0.00'));
      const traces:Data = {
        type: 'pie',
        values: COD,
        text: causeOfDeathCounts,
        labels: ['Traumatic', 'Non-Traumatic', 'Unknown'],
        hole: 0.6,
        textinfo: 'none',
        hovertemplate: ' <b style="color: %{color}; fontSize: 20px;">&#10687;</b><span> <b>%{value}%</b> n=%{text}</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        marker: {
          colors: ['#946896', '#689689', '#808080'],
        },
      };
      return [traces];
    },
  },
  watch: {
    data() {
      this.cause();
    },
  },
});
