
import { defineComponent, PropType } from 'vue';
import { surveysSubProps } from '@/utils/request';
import { generateHoverTemplates } from '@/utils/functions';
import dayjs from 'dayjs';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { Button, Empty } from 'ant-design-vue';
import lineGraph from './lineGraph.vue';

export default defineComponent({
  name: 'UnderFiveMortalityRate',
  components: {
    Button,
    lineGraph,
    Empty,
  },
  data() {
    return {
      dataExists: true,
      sex: 'All',
    };
  },
  props: {
    data: {
      type: Array as PropType<Array<surveysSubProps<'mort'>>>,
    },
    isMonth: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.underFive();
  },
  computed: {
    getLoading() {
      return this.$store.getters['surveysMortality/getLoading'];
    },
    applyFilters() {
      const mortality: surveysSubProps<'mort'>[]|undefined = this.data;
      let x:[number, surveysSubProps<'mort'>, number, number][] = [];
      if (mortality) {
        mortality.forEach((survey) => {
          if (this.sex === 'All') {
            const stats = survey?.keystats?.under5MortalityRate;
            if (typeof (stats?.stat) === 'number' && typeof (stats?.stat_low) === 'number' && typeof (stats?.stat_upp) === 'number') {
              x.push([stats?.stat, survey, stats?.stat_upp, stats?.stat_low]);
            }
          }
        });
        x = x.sort((a, b) => (a[1].surveyYear > b[1].surveyYear ? 1 : -1));
      }
      return x;
    },
  },
  methods: {
    underFive() {
      const { width, height } = (this.$refs.uFiveMRScatterGraph as Element).getBoundingClientRect();
      const t = this.renderUnderFive();
      let tick0;
      let rest;
      let dtick;
      if (!this.isMonth) {
        // @ts-expect-error cant find x in traces
        [tick0, rest] = t[0].x;
        dtick = 1;
      }
      const layout = {
        width,
        height,
        xaxis: {
          tick0,
          dtick,
          showgrid: false,
          linecolor: '#e2e2e2',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
            size: 12,
          },
        },
        yaxis: {
          rangemode: 'tozero',
          range: [0, 10.5],
          tickformat: '.1f',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
          },
        },
        legend: {
          xanchor: 'center',
          yanchor: 'top',
          y: -0.1,
          x: 0.5,
        },
        margin: {
          t: 20,
          l: 40,
          r: 20,
        },
        shapes: [
          {
            name: 'Critical',
            type: 'line',
            xref: 'paper',
            x0: 0,
            y0: 2.0,
            x1: 1,
            y1: 2.0,
            line: {
              color: 'rgb(255, 0, 0)',
              width: 2,
              dash: 'longdash',
            },
          }],
      };
      // @ts-expect-error enum error expected
      Plotly.newPlot('UnderFiveMR-scatter', t, layout);
    },
    renderUnderFive():Data[] {
      const d = this.applyFilters;
      if (this.getLoading === 'done') this.dataExists = d.length > 0;
      const x = d.map((i) => {
        let filterDate:string|number = i[1].surveyYear;
        if (this.isMonth) {
          filterDate = dayjs().set('year', i[1].surveyYear).set('month', i[1].surveyMonth - 1).format('MMM/YYYY');
        }
        return filterDate;
      });
      const y = d.map((i) => (i[0]));
      const text = d.map((i) => {
        const l = i[1];
        const loc = this.parseLocationName(l);
        return loc;
      });
      const customdata = d.map((i) => {
        const statLow = i[2];
        const statUp = i[3];
        return `${statUp.toFixed(2)} - ${statLow.toFixed(2)}`;
      });
      const hovertemplate = generateHoverTemplates(x, y, text, customdata, '#689689');
      const traces:Data = {
        x,
        y,
        text,
        customdata,
        mode: 'markers',
        type: 'scatter',
        marker: {
          color: '#689689',
          size: 8,
          opacity: 0.5,
        },
        textinfo: 'none',
        hovertemplate,
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
          align: 'left',
        },
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        connectgaps: true,
        showlegend: false,
      };
      const criticalTraces:Data = {
        x: [x[0]],
        y: [2, 2],
        showlegend: true,
        mode: 'lines',
        line: {
          color: '#f72e57',
          dash: 'dash',
        },
        name: 'Critical',
        hoverinfo: 'skip',
      };
      return [traces, criticalTraces];
    },
    parseLocationName(marker: surveysSubProps<'mort'>) {
      return marker.geographicAreaName;
    },
  },
  watch: {
    data() {
      this.underFive();
    },
  },
});
