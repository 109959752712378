
import { defineComponent, PropType } from 'vue';
import dayjs from 'dayjs';
import { surveysSubProps } from '@/utils/request';
import CrudMortality from './CrudMortality.vue';
import UnderFiveMortalityRate from './UnderFiveMortalityRate.vue';
import CauseOfDeath from './CauseOfDeath.vue';

export default defineComponent({
  name: 'MortalityResults',
  components: {
    CrudMortality,
    UnderFiveMortalityRate,
    CauseOfDeath,
  },
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
    data: {
      type: Array as PropType<Array<surveysSubProps<'mort'>>>,
    },
  },
  computed: {
    isMonth() {
      const getDate = this.$store.getters['surveysMortality/getDate'];
      let isMonth = false;
      if (!getDate[0] && getDate[1]) {
        isMonth = true;
      } else if (getDate[0] || getDate[1]) {
        const fromDate = dayjs(getDate[0]);
        const toDate = dayjs(getDate[1]);
        isMonth = Math.abs(((fromDate.year() * 100) + fromDate.month()) - ((toDate.year() * 100) + toDate.month())) <= 12;
      }
      return isMonth;
    },
  },
});
