
import { defineComponent } from 'vue';
import Mortality from '../components/Surveys/Mortality/index.vue';

export default defineComponent({
  name: 'MortalitySurveys',
  components: {
    Mortality,
  },
  async mounted() {
    await this.$store.dispatch('surveysMortality/updateLocationOptions');
    await this.$store.dispatch('surveysMortality/updateOrganizationOptions');
  },
});
