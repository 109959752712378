
import { defineComponent, PropType } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { surveysSubProps } from '@/utils/request';

export default defineComponent({
  name: 'MortalityGraphOne',
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
    data: {
      type: Array as PropType<surveysSubProps<'mort'>[]>,
      defualt: [] as surveysSubProps<'mort'>[],
      require: true,
    },
  },
  mounted() {
    this.graph();
  },
  computed: {
    graphTitle() {
      let graphTitle = 'Crude';
      if (this.viewBy === 'UnderFive') {
        graphTitle = 'Under 5';
      }
      return graphTitle;
    },
    applyFilters(): number[] {
      let mortalityData: surveysSubProps<'mort'>[] = [];
      if (this.data) mortalityData = this.data;
      let arr:(number | undefined)[] = [];
      if (this.viewBy === 'CRUD') {
        arr = mortalityData.map((x:surveysSubProps<'mort'>) => (x?.keystats?.crudeMortalityRate?.all?.stat));
      } else if (this.viewBy === 'UnderFive') {
        arr = mortalityData.map((x:surveysSubProps<'mort'>) => (x?.keystats?.under5MortalityRate.stat));
      }
      arr = arr.filter((x) => (typeof (x) === 'number'));
      return arr as number[];
    },
  },
  methods: {
    graph() {
      const { width, height } = (this.$refs.mortGraphOne as Element).getBoundingClientRect();
      const layout = {
        height: 170,
        width,
        plot_bgcolor: '#f5f5f5',
        yaxis: {
          type: 'category',
          showticklabels: false,
          linecolor: '#cdcdcd',
        },
        xaxis: {
          rangemode: 'tozero',
          zeroline: false,
          range: [0, 10.5],
          tickformat: '.1f',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular',
          },
        },
        margin: {
          t: 50,
          l: 25,
          r: 0,
        },
      };
      const data = this.renderGraphData();
      // @ts-expect-error enum error
      Plotly.newPlot('mort-graph-one', data, layout);
    },
    renderGraphData(): Data[] {
      let traces:Data = {};
      if (this.thresholds) {
        const x = this.applyFilters;
        const colorOptoins = ['#2caf80', '#e41919'];
        // @ts-expect-error this thresholds
        const colors = x.map((i:number) => (colorOptoins[this.thresholds(i)]));
        // @ts-expect-error this thresholds
        const text = x.map((i:number) => (['Non-Emergency', 'Emergency'][this.thresholds(i)]));
        const y:number[] = [];
        y.length = x.length;
        traces = {
          y: y.fill(0),
          x,
          mode: 'markers',
          textinfo: 'none',
          meta: colors,
          marker: {
            size: 10,
            color: colors,
          },
          hovertemplate: ' <b style="color: %{meta};">&#10687;</b>  <span><b>%{text}</b>  %{x}</span><extra></extra>',
          text,
          hoverlabel: {
            bgcolor: '#363534',
            bordercolor: '#363534',
            font: {
              color: '#FFFFFF',
              family: 'DINNextLTPro',
              size: 12.5,
            },
          },
        };
      }
      return [traces];
    },
  },
  watch: {
    applyFilters() {
      this.graph();
    },
  },
});
