import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mort-graphs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MortalityGraphOne = _resolveComponent("MortalityGraphOne")!
  const _component_MortalityGraphTwo = _resolveComponent("MortalityGraphTwo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MortalityGraphOne, {
      viewBy: _ctx.viewBy,
      thresholds: _ctx.thresholds,
      data: _ctx.data
    }, null, 8, ["viewBy", "thresholds", "data"]),
    _createVNode(_component_MortalityGraphTwo, {
      class: "mort-prof",
      viewBy: _ctx.viewBy,
      thresholds: _ctx.thresholds,
      data: _ctx.data
    }, null, 8, ["viewBy", "thresholds", "data"])
  ]))
}