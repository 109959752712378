
import { defineComponent } from 'vue';
import { CaretDownFilled } from '@ant-design/icons-vue';
import { Select } from 'ant-design-vue';
import { surveysSubProps } from '@/utils/request';
import MortalityResults from './MortalityResults/index.vue';
import MortalityMap from './MortalityMap/index.vue';
import MortalityGraphs from './MortalityGraphs/index.vue';

const { Option } = Select;

export default defineComponent({
  name: 'Mortality',
  components: {
    MortalityResults,
    MortalityMap,
    MortalityGraphs,
    CaretDownFilled,
    Select,
    Option,
  },
  data() {
    return {
      viewBy: 'CRUD',
      loc: 'All',
    };
  },
  methods: {
    thresholds(num: number): number {
      const { viewBy } = this;
      let n = 0;
      if (viewBy === 'CRUD') {
        if (num >= 1) n = 1;
      } else if (viewBy === 'UnderFive') {
        if (num >= 2) n = 1;
      }
      return n;
    },
  },
  computed: {
    getLoading(): string {
      const l = this.$store.getters['surveysMortality/getLoading'];
      return l;
    },
    getData():surveysSubProps<'mort'> {
      let mortalityData = this.$store.getters['surveysMortality/getData'];
      mortalityData = mortalityData.filter((s: surveysSubProps<'mort'>) => {
        let markerInfo;
        if (this.viewBy === 'CRUD') markerInfo = s.keystats?.crudeMortalityRate?.all?.stat;
        if (this.viewBy === 'UnderFive') markerInfo = s.keystats?.under5MortalityRate?.stat;
        return (typeof (markerInfo) === 'number' && s?.surveyYear && s?.surveyMonth && s?.longitude && s?.latitude);
      });
      return mortalityData;
    },
  },
  watch: {
    getLoading(newProps, oldProps) {
      if (newProps === 'done' && oldProps === 'loading') {
        const getLoc = this.$store.getters['surveysMortality/getLocation'];
        if (getLoc) {
          this.loc = getLoc;
        } else {
          this.loc = 'All';
        }
      }
    },
  },
});
