
import { defineComponent, PropType } from 'vue';
import { surveysSubProps } from '@/utils/request';
import MapResults from '@/components/Surveys/MapResults/index.vue';
import darkGreenPlus from '@/assets/svg/darkGreenPlus.svg';
import darkGreenMarker from '@/assets/svg/dark-green-marker.svg';
import lightRedMarker from '@/assets/svg/red-marker.svg';
import lightRedPlus from '@/assets/svg/lightRedPlus.svg';
import triLightGreenMarker from '@/assets/svg/triangleMarker/darkGreenTri.svg';
import triLightRedPlus from '@/assets/svg/triangleMarker/redTriPlus.svg';
import triLightGreenPlus from '@/assets/svg/triangleMarker/darkGreenTriPlus.svg';
import triLightRedMarker from '@/assets/svg/triangleMarker/redTri.svg';

export default defineComponent({
  name: 'MortalityMap',
  components: {
    MapResults,
  },
  props: {
    thresholds: {
      type: Function,
    },
    viewBy: {
      type: String,
    },
    data: {
      type: Array as PropType<surveysSubProps<'mort'>[]>,
      defualt: [] as surveysSubProps<'mort'>[],
    },
  },
  computed: {
    isFiltered() {
      return !!this.getRegion;
    },
    getRegion() {
      const loc = this.$store.getters['surveysMortality/getLocation'];
      return loc;
    },
    getMapData() {
      const mapData:surveysSubProps<'mort'>[] = [];
      const { data } = this;
      if (data) {
        data.forEach((s: surveysSubProps<'mort'>) => {
          let inMapDataIndex = mapData.findIndex((m) => ((m.latitude && m.latitude.toFixed(2) === (s.latitude && s.latitude.toFixed(2))) && (m.longitude && m.longitude.toFixed(2) === (s.latitude && s.longitude.toFixed(2)))));
          if (inMapDataIndex === -1) {
            mapData.push({ ...s, previousSurveys: undefined });
            inMapDataIndex = mapData.length - 1;
          }
          const survey = mapData[inMapDataIndex];
          if (typeof survey.previousSurveys === 'undefined') {
            survey.previousSurveys = 0;
          } else {
            survey.previousSurveys += 1;
          }
          if (s.surveyMonth >= survey.surveyMonth && s.surveyYear >= survey.surveyYear) {
            mapData[inMapDataIndex] = { ...s, previousSurveys: survey.previousSurveys };
          }
        });
      }
      return mapData;
    },
  },
  methods: {
    filterColor(markerData:surveysSubProps<'mort'>) {
      let marker = darkGreenMarker;
      const { previousSurveys, surveyType } = markerData;
      let markerInfo;
      if (this.viewBy === 'CRUD') markerInfo = markerData.keystats?.crudeMortalityRate?.all?.stat;
      if (this.viewBy === 'UnderFive') markerInfo = markerData.keystats?.under5MortalityRate?.stat;
      // thresholds
      console.log(markerInfo);
      if (surveyType !== 'NNS' && this.thresholds && typeof (markerInfo) === 'number') {
        if (previousSurveys) {
          const markerSvgs = [darkGreenPlus, lightRedPlus];
          marker = markerSvgs[(this.thresholds(markerInfo))];
        } else {
          const markerSvgs = [darkGreenMarker, lightRedMarker];
          marker = markerSvgs[(this.thresholds(markerInfo))];
        }
      } else if (this.thresholds && typeof (markerInfo) === 'number') {
        if (previousSurveys && previousSurveys > 0) {
          const markerSvgs = [triLightGreenPlus, triLightRedPlus];
          marker = markerSvgs[(this.thresholds(markerInfo))];
        } else {
          const markerSvgs = [triLightGreenMarker, triLightRedMarker];
          marker = markerSvgs[(this.thresholds(markerInfo))];
        }
      }
      return marker;
    },
    async onPopoverClick(m: surveysSubProps) {
      let loc = m.geographicCountry;
      let scope = 'National';
      if (m.administrative_area_level_1) {
        loc = m.administrative_area_level_1;
        scope = 'Sub-National';
      } else if (m.administrative_area_level_2) {
        loc = m.administrative_area_level_2;
        scope = 'Sub National';
      } else if (m.administrative_area_level_3) {
        loc = m.administrative_area_level_3;
        scope = 'Sub National';
      }
      await this.$store.dispatch('surveysMortality/updateGeographicName', scope);
      await this.$store.dispatch('surveysMortality/updateLocation', loc);
      await this.$store.dispatch('surveysMortality/updateData').then(() => {
        (this.$refs.mortMapRef as typeof MapResults).zoomLevel = 7;
        (this.$refs.mortMapRef as typeof MapResults).position.latitude = m.latitude;
        (this.$refs.mortMapRef as typeof MapResults).position.longitude = m.longitude;
      });
    },
  },
});
