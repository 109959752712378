import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapResults = _resolveComponent("MapResults")!

  return (_openBlock(), _createBlock(_component_MapResults, {
    markers: _ctx.getMapData,
    markerMaker: _ctx.filterColor,
    region: _ctx.getRegion,
    onPopoverClick: _ctx.onPopoverClick,
    ref: "mortMapRef",
    isFiltered: _ctx.isFiltered
  }, null, 8, ["markers", "markerMaker", "region", "onPopoverClick", "isFiltered"]))
}